import React, { useState } from "react";

import {
  analyticsCustomEvent,
  analyticsInit,
  analyticsPageview,
} from "../utils";

const GA4TestPage = () => {
  React.useEffect(() => {
    analyticsInit();
    analyticsPageview("ga4testpage");
  }, []);

  const [p1, setP1] = useState("");
  const [p2, setP2] = useState("");

  const handleCustomGA4Event = () => {
    analyticsCustomEvent("ga4testpage", "customga4event");
  };

  const handleDynamicGA4Event = () => {
    analyticsCustomEvent(p1, p2);
  };

  return (
    <main style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      Google Analytics v4 test page
      <button onClick={() => handleCustomGA4Event()}>
        send custom GA4 event ('customga4event')
      </button>
      <input
        type="text"
        value={p1}
        onChange={(e) => setP1(e.target.value)}
        placeholder="param 1"
      />
      <input
        type="text"
        value={p2}
        onChange={(e) => setP2(e.target.value)}
        placeholder="param 2"
      />
      <button onClick={() => handleDynamicGA4Event()}>
        send dynamic GA4 event
      </button>
    </main>
  );
};

export default GA4TestPage;
